import React, { useState, Fragment } from "react"
import Moment from "react-moment"
import ReactModal from "react-modal"
import { useStaticQuery, graphql } from "gatsby"

const Workshops = ({ workshops, description }) => {
  const data = useStaticQuery(graphql`
    {
      patternImage: file(relativePath: { eq: "pattern.svg" }) {
        publicURL
      }
    }
  `)

  const [openModal, setOpenModal] = useState(null)

  const handleClick = id => {
    setOpenModal(id)
  }

  const handleKeyDown = (keyCode, id) => {
    if (keyCode === 13) {
      setOpenModal(id)
    }
  }

  const close = () => {
    setOpenModal(null)
  }

  const getWorkshopDivs = innerWorkshops => {
    const workshopDivs = innerWorkshops.map(workshop => {
      const { title, start, end, speaker, image } = workshop.node.frontmatter
      const { id, html } = workshop.node
      const backgroundImageStyle = {
        backgroundImage: `url('${image.publicURL}')`,
      }
      return (
        <Fragment key={id}>
          <ReactModal onRequestClose={close} isOpen={openModal === id}>
            <button className="closeButton" onClick={close}>
              &times;
            </button>
            <div className="single-info-modal">
              <div className="workshop-img-wrap no-workshop-img">
                <div
                  className="workshop-img"
                  style={backgroundImageStyle}
                ></div>
              </div>

              <div className="single-info-main">
                <div className="single-title">{title}</div>
                <div className="single-speaker">w/ {speaker}</div>
                <div className="single-additional">
                  <Moment format="dddd, MMMM D, YYYY - h:mm A">{start}</Moment>{" "}
                  to <Moment format="h:mm A">{end}</Moment>
                </div>
              </div>
              <div
                className="single-info-desc"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </ReactModal>
          <div
            key={title}
            className="single-workshop"
            onClick={() => handleClick(id)}
            onKeyDown={ev => handleKeyDown(ev.keyCode, id)}
            tabIndex={0}
            role="button"
          >
            <div className="workshop-img-wrap">
              <div className="workshop-img" style={backgroundImageStyle}></div>
            </div>
            <div className="workshop-info-wrap">
              <div className="workshop-title">{title}</div>
              <div className="workshop-extra">w/ {speaker}</div>
            </div>
            <div className="icon icon-info"></div>
          </div>
        </Fragment>
      )
    })
    return workshopDivs
  }

  const workshopDayDivs = workshops.group.map(workshopDays => {
    const day = workshopDays.edges.length > 0 ? workshopDays.edges[0].node.frontmatter.start : workshopDays.fieldValue
    return (
      <div
        key={workshopDays.fieldValue}
        id={workshopDays.fieldValue.toString()}
        className="workshop-grid-day"
      >
        <div className="workshop-grid-day-name">
          <div className="workshop-grid-day-name-day">
            <Moment format="dddd">{day}</Moment>
          </div>
          <div className="workshop-grid-day-name-date">
            <Moment format="MMMM D, YYYY">{day}</Moment>
          </div>
        </div>

        <div className="workshop-grid-day-list">
          {getWorkshopDivs(workshopDays.edges)}
        </div>
      </div>
    )
  })

  return workshops.length > 0 ? (
    <section
      className="workshop-section"
      style={{ backgroundImage: `url('${data.patternImage.publicURL}')` }}
    >
      <header className="section-header">
        <div className="center">
          <h3>Upcoming Forward Workshops</h3>
          <h4>{description}</h4>
        </div>
      </header>
      <div className="workshop-grid">{workshopDayDivs}</div>
    </section>
  ) : null
}

export default Workshops
