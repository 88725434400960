import React, { useState, Fragment } from "react"
import Moment from "react-moment"
import ReactModal from "react-modal"
import { useStaticQuery, graphql } from "gatsby"

const Labs = ({ labs, description }) => {
  const [openModal, setOpenModal] = useState(null)

  const data = useStaticQuery(graphql`
    {
      patternImage: file(relativePath: { eq: "dark-pattern.png" }) {
        publicURL
      }
    }
  `)

  const handleClick = id => {
    setOpenModal(id)
  }

  const handleKeyDown = (keyCode, id) => {
    if(keyCode === 13) {
      setOpenModal(id)
    }
  }

  const close = () => {
    setOpenModal(null)
  }

  const getLabDivs = innerLabs => {
    const labDivs = innerLabs.map(lab => {
      const {
        title,
        address,
        start,
        end,
        speaker,
        image
      } = lab.node.frontmatter
      const {
        id,
        html
      } = lab.node
      const backgroundImageStyle = {
        backgroundImage: `url('${image.publicURL}')`,
      }
      return (
        <Fragment key={id}>
          <ReactModal
            onRequestClose={close}
            isOpen={openModal === id}
          >
            <div className="single-info-modal">
              <div className="workshop-img-wrap no-workshop-img">
                <div className="workshop-img" style={backgroundImageStyle}></div>
              </div>

              <div className="single-info-main">
                <div className="single-title">{title}</div>
                <div className="single-speaker">
                  w/ {speaker}
                </div>
                <div className="single-additional">{address}</div>
                <div className="single-additional">
                  <Moment format="dddd, MMMM D, YYYY - h:mm A">
                    {start}
                  </Moment>{" "}
                  to <Moment format="h:mm A">{end}</Moment>
                </div>
              </div>
              <div className="single-info-desc" dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </ReactModal>
          <div
            key={title}
            className="single-workshop"
            onClick={() => handleClick(id)}
            onKeyDown={ev => handleKeyDown(ev.keyCode, id)}
            tabIndex={0}
            role="button"
          >
            <div className="workshop-img-wrap">
              <div className="workshop-img" style={backgroundImageStyle}></div>
            </div>
            <div className="workshop-info-wrap">
              <div className="workshop-title">{title}</div>
              <div className="workshop-extra">
                w/ {speaker}
              </div>
            </div>
            <div className="icon icon-info"></div>
          </div>
        </Fragment>
      )
    })
    return labDivs
  }

  const labDayDivs = labs.group.map(labDays => {
    const day = labDays.edges.length > 0 ? labDays.edges[0].node.frontmatter.start : labDays.fieldValue
    return (
      <div
        key={labDays.fieldValue}
        id={labDays.fieldValue.toString()}
        className="workshop-grid-day"
      >
        <div className="workshop-grid-day-name">
          <div className="workshop-grid-day-name-day">
            <Moment format="dddd">{day}</Moment>
          </div>
          <div className="workshop-grid-day-name-date">
            <Moment format="MMMM D, YYYY">{day}</Moment>
          </div>
        </div>

        <div className="workshop-grid-day-list">
          {getLabDivs(labDays.edges)}
        </div>
      </div>
    )
  })

  return (
    <section className="lab-section" style={{backgroundImage: `url('${data.patternImage.publicURL}')`}}>
      <header className="section-header">
        <div className="center">
          <h3>Upcoming Forward Labs</h3>
          <h4>
            {description}
          </h4>
        </div>
      </header>
      <div className="workshop-grid">{labDayDivs}</div>
    </section>
  )
}

export default Labs
