import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Lectures = ({lectures}) => {
  const data = useStaticQuery(graphql`
    {
      codeImage: file(relativePath: { eq: "code-bg.jpg" }) {
        publicURL
      }
    }
  `)

  const lectureSpans = lectures.edges.map(lecture => {
    const { title } = lecture.node.frontmatter
    const { id } = lecture.node
    return (
        <span key={id} className="lecture-list-lecture">{title}</span>
    )
  })

  return (
    <section
      className="lecture-list-section large-section"
      style={{ backgroundImage: `url(${data.codeImage.publicURL})` }}
    >
        <h3>Topics include:</h3>

        <div className="lecture-list">{lectureSpans}</div>
    </section>
  )
}

export default Lectures
