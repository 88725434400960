import React from "react"
import Img from "gatsby-image"

const Sponsors = ({ sponsors }) => {
  const createSponsorDivs = sponsors => {
    return sponsors.map(sponsor => {
      const { image } = sponsor.node.frontmatter
      const { id } = sponsor.node
      return (
        <div key={id} className="sponsor-item">
          <Img
            className="sponsor-item-img"
            imgStyle={{ objectFit: "scale-down" }}
            fixed={image.childImageSharp.fixed}
            alt=""
          />
        </div>
      )
    })
  }
  const goldSponsorDivs = createSponsorDivs(
    sponsors.edges.filter(sponsor => sponsor.node.frontmatter.level === "gold")
  )
  const silverSponsorDivs = createSponsorDivs(
    sponsors.edges.filter(
      sponsor => sponsor.node.frontmatter.level === "silver"
    )
  )
  const bronzeSponsorDivs = createSponsorDivs(
    sponsors.edges.filter(
      sponsor => sponsor.node.frontmatter.level === "bronze"
    )
  )

  return (
    <section className="sponsor-section large-section">
      <div className="inner">
        <h4>A huge thanks to our sponsors:</h4>

        <div className="sponsor-header">
          <div className="separator" />
          <h2>Gold</h2>
          <div className="separator" />
        </div>
        <div className="sponsors-premier fadeIn">
          <div className="logos-wrap">{goldSponsorDivs}</div>
        </div>
        <div className="sponsor-header">
          <div className="separator" />
          <h2>Silver</h2>
          <div className="separator" />
        </div>
        <div className="sponsors-premier fadeIn">
          <div className="logos-wrap">{silverSponsorDivs}</div>
        </div>
        <div className="sponsor-header">
          <div className="separator" />
          <h2>Bronze</h2>
          <div className="separator" />
        </div>
        <div className="sponsors-premier fadeIn">
          <div className="logos-wrap">{bronzeSponsorDivs}</div>
        </div>
      </div>
    </section>
  )
}

export default Sponsors
