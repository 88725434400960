import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

import Lectures from "../../components/lectures"
import Speakers from "../../components/speakers"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Workshops from "../../components/workshops"
import Labs from "../../components/labs"
import Sponsors from "../../components/sponsors"

const IndexPage = ({ data }) => {
  const [showOverlay, setShowOverlay] = useState(true)

  useEffect(() => {
    window.wistiaInit = function(W) {
      var s = W.api("intro_video")
      s.bind("play", function() {
        setShowOverlay(false)
      })
    }
  }, [])

  return (
    <Layout page="ottawa">
      <Helmet>
        <script src="//fast.wistia.com/assets/external/E-v1.js" async></script>
      </Helmet>
      <SEO title="ForwardJS Ottawa 2020" />
      <section
        className="hero web-hero large-section thanks has-banner"
        style={{ backgroundImage: `url(${data.bgImage.publicURL})` }}
      >
        <div className="fadeOverlay"></div>
        <div className="inner fadeIn" data-wow-duration="1s">
          <h1>May 27-29 2020<br />in <span className="striked">Ottawa</span> The Interweb</h1>

          <h3>Due to the COVID-19 situation we have decided to make ForwardJS Ottawa a virtual event, providing four days of workshops and talks to registrants from the comfort of their browser.<br /><br /></h3>

          <h2>
            <a
              className="what-is-forward"
              href="https://www.crowdcast.io/e/forwardjs-may-2020"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tickets for ForwardJS Ottawa
            </a>
            <br />
            <br />
            <a
              className="what-is-forward"
              href="/ottawa/schedule"
            >
              See the Schedule
            </a>
            <br />
            <br />
            <a
              className="what-is-forward"
              href="http://bit.ly/forwardjs-ottawa-sponsor"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sponsor ForwardJS Ottawa
            </a>
          </h2>
        </div>
      </section>
      <Speakers speakers={data.speakers} />
      <Lectures lectures={data.speakers} />
      <Workshops
        workshops={data.workshops}
        description="The day before the conference, we'll hold an in-depth workshop with
            a top instructor."
      />
      <Labs
        labs={data.labs}
        description="A series of free labs by some of our wonderful sponsors."
      />
      <Sponsors
        sponsors={data.sponsors}
       />
      <section id="this-is-forward" className="video-section large-section">
        {showOverlay ? (
          <h3 className="video-overlay-header">This is Forward.</h3>
        ) : null}
        <div className="video-wrapper">
          <div
            id="intro_video"
            className="video wistia_embed wistia_async_ba84q1a3n8 videoFoam=true"
          >
            &nbsp;
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    bgImage: file(relativePath: { eq: "bg-image-masks.jpg" }) {
      publicURL
    }
    sponsors: allMarkdownRemark(filter: {frontmatter: {type: {eq: "sponsor"}}}) {
      edges {
        node {
          frontmatter {
            level
            image {
              childImageSharp {
                fixed {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
    workshops: allMarkdownRemark(
      filter: {
        frontmatter: { conference: { eq: "ottawa" }, type: { eq: "workshop" } }
      }
    ) {
      group(field: frontmatter___day) {
        edges {
          node {
            id
            html
            frontmatter {
              address
              end
              image {
                publicURL
              }
              speaker
              title
              start
            }
          }
        }
        fieldValue
      }
    }
    labs: allMarkdownRemark(
      filter: {
        frontmatter: { conference: { eq: "ottawa" }, type: { eq: "lab" } }
      }
    ) {
      group(field: frontmatter___day) {
        edges {
          node {
            id
            html
            frontmatter {
              address
              end
              image {
                publicURL
              }
              speaker
              title
              start
            }
          }
        }
        fieldValue
      }
    }
    speakers: allMarkdownRemark(
      filter: {
        frontmatter: { type: { eq: "talk" }, conference: { eq: "ottawa" } }
      },
      sort: {fields: fields___createdTime}
    ) {
      edges {
        node {
          id
          frontmatter {
            image {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            type
            company
            github
            remoteImage
            position
            title
            speaker
            tracks
            twitter
            imageVertical
          }
          html
        }
      }
    }
  }
`
