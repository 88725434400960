import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Speakers = ({ speakers }) => {
  const data = useStaticQuery(graphql`
    {
      darkPatternImage: file(relativePath: { eq: "dark-pattern.png" }) {
        publicURL
      }
    }
  `)

  const speakerDivs = speakers.edges.map(spk => {
    const {
      image,
      remoteImage,
      speaker,
      position,
      company,
      imageVertical,
    } = spk.node.frontmatter
    const { id } = spk.node
    const style = {
      backgroundImage: `url(${
        remoteImage ? remoteImage : image.childImageSharp.fluid.src
      })`,
    }
    if (imageVertical != null) {
      style.backgroundPosition = `${imageVertical} center`
    }
    return (
      <div key={id} className="speaker-img-grid-item">
        <div className="speaker-img" style={style}></div>
        <div className="speaker-img-grid-name">
          {speaker},
          <span className="speaker-img-grid-job">
            {position} at {company}
          </span>
        </div>
      </div>
    )
  })

  return (
    <section
      className="speaker-section large-section"
      style={{ backgroundImage: `url(${data.darkPatternImage.publicURL})` }}
    >
      <div className="inner fadeIn" data-wow-duration="2s">
        <h3 className="down-50">Forward Thinkers of the Web Unite!</h3>

        <div className="speaker-img-grid">{speakerDivs}</div>
      </div>
    </section>
  )
}

export default Speakers
